<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div :hidden="true">
      {{ updateKey }}
    </div>
    <Dialog
      v-model:visible="displayDiscountCreationDialog"
      header="Discount creation"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      :modal="true"
      @hide="closeDiscountCreation"
    >
      <div class="flex flex-wrap pb-5">
        <div class="mr-4">
          <label
            for="campaignName"
            class="block pb-2 text-base font-semibold"
          >
            <span>Discount code</span>
          </label>
          <InputText
            id="discountCode"
            v-model="values.code"
            type="text"
            :class="['mr-1', !!errors.code && 'p-invalid']"
            placeholder="Discount code..."
            @blur="validate('code')"
            @keypress="validate('code')"
          />
          <small
            v-if="!!errors.code"
            class="block"
          >
            {{ errors.code }}
          </small>
        </div>

        <div class="mr-1">
          <label
            for="campaignName"
            class="block pb-2 text-base font-semibold"
          >
            <span>Discount description</span>
          </label>
          <InputText
            id="discountDescription"
            v-model="values.description"
            type="text"
            :class="['mr-1', !!errors.description && 'p-invalid']"
            placeholder="Discount description..."
            @blur="validate('description')"
            @keypress="validate('description')"
          />
          <small
            v-if="!!errors.description"
            class="block"
          >
            {{ errors.description }}
          </small>
        </div>
      </div>

      <div class="flex flex-wrap pb-5">
        <div class="mr-4">
          <label
            for="campaignName"
            class="block pb-2 text-base font-semibold"
          >
            <span>Discount rate (%)</span>
          </label>
          <InputNumber
            id="discountPercentage"
            v-model="values.value"
            :use-grouping="false"
            suffix="%"
            :min="1"
            :max="100"
            :class="['w-3', !!errors.value && 'p-invalid']"
            locale="en-US"
            @blur="validate('value')"
            @keypress="validate('value')"
          />
          <small
            v-if="!!errors.value"
            class="block"
          >
            {{ errors.value }}
          </small>
        </div>

        <div class="mr-2">
          <label
            for="campaignName"
            class="block pb-2 text-base font-semibold"
          >
            <span>Discount duration (in months)</span>
          </label>
          <InputNumber
            id="discountDuration"
            v-model="values.duration"
            :min="1"
            :max-fraction-digits="0"
            :use-grouping="false"
            :class="['w-3', !!errors.duration && 'p-invalid']"
            locale="en-US"
            :allow-empty="false"
            @blur="validate('duration')"
            @keypress="validate('duration')"
          />
          <small
            v-if="!!errors.duration"
            class="block"
          >
            {{ errors.duration }}
          </small>
        </div>

        <div class="mr-2">
          <label
            for="campaignName"
            class="block pb-2 text-base font-semibold"
          >
            <span>Redeem before date</span>
          </label>
          <Calendar
            v-model="values.redeemBefore"
            selection-mode="single"
            :show-icon="true"
            :manual-input="false"
            date-format="yy-mm-dd"
            :min-date="getMinDate()"
            :class="['mr-1', !!errors.redeemBefore && 'p-invalid']"
            panel-class="min-w-min w-10rem"
            @blur="validate('redeemBefore')"
            @keypress="validate('redeemBefore')"
            @date-select="validate('redeemBefore')"
          />
          <small
            v-if="!!errors.redeemBefore"
            class="block"
          >
            {{ errors.redeemBefore }}
          </small>
        </div>
      </div>

      <template #footer>
        <Button
          label="Cancel"
          class="p-button-secondary"
          autofocus
          :disabled="creatingDiscount"
          @click="closeDiscountCreation"
        />
        <Button
          label="Create"
          :disabled="creatingDiscount"
          @click="createDiscountHandler"
        />
      </template>
    </Dialog>
    <Button
      label="Create new discount..."
      :disabled="creatingDiscount"
      class="mb-3"
      @click="openDiscountCreation"
    />
    <DataTable
      v-model:filters="filters"
      :value="discounts"
      responsive-layout="scroll"
      :scrollable="true"
      scroll-height="800px"
      scroll-direction="both"
      :global-filter-fields="['code']"
      context-menu
      :rows="20"
      paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows-per-page-options="[10, 20, 50]"
      :paginator="true"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
    >
      <template #empty>
        No data found.
      </template>

      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Search by code..."
            />
          </span>
        </div>
      </template>

      <Column
        field="code"
        header="Code"
        :sortable="true"
        frozen
        class="invitation-email"
      />
      <Column
        field="description"
        header="Description"
        :sortable="false"
        class="invitation-code"
      />
      <Column
        field="value"
        header="Discount rate"
        :sortable="false"
      >
        <template #body="slotProps">
          <span>{{ slotProps.data.value.type === "FIXED" ? `\$${slotProps.data.value.value}` : `${slotProps.data.value.value}%` }}</span>
        </template>
      </Column>
      <Column
        field="creationDate"
        header="Creation date"
        :sortable="true"
        class="account-login"
      >
        <template #body="slotProps">
          {{ formatLocalDate(slotProps.data.creationDate) }}
        </template>
      </Column>
      <Column
        field="expirationDate"
        header="Redeem before"
        :sortable="true"
        class="justify-content-center"
      >
        <template #body="slotProps">
          {{ formatLocalDate(slotProps.data.expirationDate) }}
        </template>
      </Column>
      <Column
        field="duration"
        header="Duration period"
        :sortable="true"
        class="justify-content-center"
      />
      <Column
        field="id"
        header=""
        :sortable="false"
        class="justify-content-center"
      >
        <template #body="slotProps">
          <Button
            type="button"
            label="Cancel discount"
            class="p-button-sm p-button-danger"
            :disabled="cancellingDiscount"
            @click="cancelDiscount(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import { number, object, string } from 'yup';
import dayjs from 'dayjs';
import A2CAAuthorizedClient from '../../../api/a2caAuthorizedClient';

const DEFAULT_DISCOUNT_VALUES = {
  code: '',
  description: '',
  value: 1.0,
  duration: 1,
  redeemBefore: '',
};

const createDiscountSchema = object().shape({
  code: string().required(),
  description: string().required(),
  value: number().required(),
  duration: number().required(),
  redeemBefore: string().required(),
});

export default {
  components: { },
  inject: ['query'],
  props: {
  },
  data() {
    return {
      loading: false,
      updateKey: 0,
      discounts: null,
      displayDiscountCreationDialog: false,
      creatingDiscount: false,
      cancellingDiscount: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

      },
      values: {
        code: '',
        description: '',
        value: 1.0,
        duration: 1,
        redeemBefore: '',
      },
      errors: {
        code: '',
        description: '',
        value: '',
        duration: '',
        redeemBefore: '',
      },
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  async created() {
    this.loading = true;

    const client = new A2CAAuthorizedClient(this.query);
    const discounts = await client.getAllDiscounts();
    this.discounts = discounts;

    this.loading = false;
  },
  methods: {
    getMinDate() {
      return dayjs().add(1, 'day').toDate();
    },
    formatLocalDate(date) {
      return dayjs(date).format('YYYY-DD-MM');
    },
    openDiscountCreation() {
      this.displayDiscountCreationDialog = true;
    },
    closeDiscountCreation() {
      this.displayDiscountCreationDialog = false;
      this.values = DEFAULT_DISCOUNT_VALUES;
    },
    createDiscountHandler() {
      const client = new A2CAAuthorizedClient(this.query);
      createDiscountSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.errors = {};
          this.creatingDiscount = true;
          try {
            const createdDiscount = await client.createDiscount(this.values.code, this.values.description, this.values.value, this.values.duration, this.values.redeemBefore);
            if (createdDiscount?.type) {
              this.$toast.add({
                severity: 'error',
                summary: 'Could not create discount',
                detail: 'The discount code already exists.',
                life: 3000,
              });
              this.creatingDiscount = false;
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Discount created',
                detail: `A discount has been created with code ${this.values.code}`,
                life: 3000,
              });
              this.discounts = [createdDiscount, ...this.discounts];
              this.creatingDiscount = false;
              this.displayDiscountCreationDialog = false;
              this.values = DEFAULT_DISCOUNT_VALUES;
            }
          } catch (err) {
            this.$toast.add({
              severity: 'error',
              summary: 'Could not send invitation email',
              detail: err,
              life: 3000,
            });
          }
          this.creatingDiscount = false;
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
          this.$toast.add({
            severity: 'error',
            summary: 'Could not create discount',
            detail: 'Could not create new discount.',
            life: 3000,
          });
        });
    },
    async cancelDiscount(discountId) {
      this.cancellingDiscount = true;
      const client = new A2CAAuthorizedClient(this.query);
      const deleteResponse = await client.cancelDiscount(discountId);
      if (deleteResponse?.type) {
        this.$toast.add({
          severity: 'error',
          summary: 'Could not delete discount',
          detail: 'The discount does not exist.',
          life: 3000,
        });
      } else {
        this.$toast.add({
          severity: 'success',
          summary: 'Discount cancelled',
          detail: 'The discount was cancelled successfully',
          life: 3000,
        });
        this.discounts = [...this.discounts.filter((discount) => discount.id !== discountId)];
      }
      this.cancellingDiscount = false;
    },
    validate(field) {
      createDiscountSchema
        .validateAt(field, this.values, { abortEarly: true })
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },
  },
};
</script>
<style>
.invitation-email {
  min-width: 250px;
}
.invitation-code {
  min-width: 300px;
}
.account-login {
  min-width: 180px;
}
th, td {
  width: 120px;
}
</style>
<style scoped lang="scss">
@import "../../../assets/styles/dashboard/invitation.scss";
</style>
